import {
  Filter,
  FilterType,
  Sort,
  SortType,
  createFilter,
  createFilterPayload,
  createRangeFilter
} from '@retail/gql-utils';
import { endOfDay, format, startOfDay } from 'date-fns';
import { assign, flow, head, isEmpty } from 'lodash/fp';

import { NO_ASSIGNEE, NO_TASK_ACTION, TASK_STATUS } from './constants';

import { DEFAULT_SORTING } from '~constants/common';
import { DATE_TIME_FORMAT_BE } from '~constants/date';
import { TaskManagerSearchModel } from '~types/TaskManagerSearchModel';

function createDateRangeFilter(field: string, value: string[]) {
  if (value?.filter(Boolean).length === 2) {
    const [startDate, endDate] = value.map((x) => new Date(x));

    return createRangeFilter(
      field,
      JSON.stringify([
        [
          FilterType.GREATER_OR_EQUAL,
          format(startOfDay(startDate), DATE_TIME_FORMAT_BE)
        ],
        [
          FilterType.LESS_OR_EQUAL,
          format(endOfDay(endDate), DATE_TIME_FORMAT_BE)
        ]
      ])
    ) as Filter;
  }

  return null;
}

export default (defaultValues: TaskManagerSearchModel = {}) =>
  flow(
    assign(defaultValues),
    ({
      page: rawPage,
      assignee,
      country,
      createdOn,
      completedOn,
      orderStatus,
      orderNumber,
      stockNumber,
      taskType,
      taskStatus,
      sort,
      direction,
      pageSize,
      financingStatus,
      paymentStatus,
      paymentType,
      flowVersion,
      action
    }: TaskManagerSearchModel) => {
      const page = Number(rawPage) - 1 || 0;
      const filters: Filter[] = [];
      const sorts: Sort[] = [];

      if (!isEmpty(financingStatus)) {
        filters.push(
          createFilter('financingStatus', FilterType.IN, financingStatus)
        );
      }

      if (!isEmpty(paymentStatus)) {
        filters.push(
          createFilter('paymentStatus', FilterType.IN, paymentStatus)
        );
      }

      if (!isEmpty(flowVersion)) {
        filters.push(createFilter('flowVersion', FilterType.IN, flowVersion));
      }

      if (!isEmpty(paymentType)) {
        filters.push(createFilter('paymentType', FilterType.IN, paymentType));
      }

      if (!isEmpty(assignee)) {
        const assigneeFilters = [];

        if (assignee.includes(NO_ASSIGNEE)) {
          assigneeFilters.push(
            createFilter('assignee', FilterType.IS_NULL, null)
          );
        }

        const otherAssignees = assignee.filter((item) => item !== NO_ASSIGNEE);

        if (otherAssignees.length > 0) {
          assigneeFilters.push(
            createFilter('assignee', FilterType.IN, otherAssignees)
          );
        }

        if (assignee.includes(NO_ASSIGNEE) && otherAssignees?.length > 0) {
          filters.push(createFilter(null, FilterType.OR, assigneeFilters));
        } else {
          filters.push(head(assigneeFilters));
        }
      }

      if (taskStatus) {
        filters.push(createFilter('state', FilterType.EQUAL, taskStatus));
      }

      if (!isEmpty(country)) {
        filters.push(
          createFilter(
            'retailCountry',
            country.length > 1 ? FilterType.IN : FilterType.EQUAL,
            country.length > 1 ? country : country[0]
          )
        );
      }

      if (!isEmpty(orderStatus)) {
        filters.push(createFilter('orderState', FilterType.IN, orderStatus));
      }

      if (orderNumber) {
        filters.push(
          createFilter(
            'orderNumber',
            FilterType.EQUAL,
            orderNumber.trim().toUpperCase()
          )
        );
      }

      if (stockNumber) {
        filters.push(
          createFilter(
            'stockNumber',
            FilterType.EQUAL,
            stockNumber.trim().toUpperCase()
          )
        );
      }

      if (!isEmpty(taskType)) {
        filters.push(
          createFilter('taskDefinitionKey', FilterType.IN, taskType)
        );
      }

      if (!isEmpty(action)) {
        const actionFilters = [];

        if (action.includes(NO_TASK_ACTION)) {
          actionFilters.push(createFilter('action', FilterType.IS_NULL, null));
        }

        const otherActions = action.filter((item) => item !== NO_TASK_ACTION);

        if (otherActions.length > 0) {
          actionFilters.push(
            createFilter('action', FilterType.IN, otherActions)
          );
        }

        if (action.includes(NO_TASK_ACTION) && otherActions?.length > 0) {
          filters.push(createFilter(null, FilterType.OR, actionFilters));
        } else {
          filters.push(head(actionFilters));
        }
      }

      filters.push(
        ...[
          createDateRangeFilter('createdOn', createdOn),
          createDateRangeFilter('completedOn', completedOn)
        ].filter(Boolean)
      );

      let filter: Filter = null;

      if (filters.length) {
        filter =
          filters.length > 1
            ? createFilter(null, FilterType.AND, filters)
            : filters[0];
      }

      if (sort && direction) {
        let sortDirection = direction;

        if (sort === 'createdOn' || sort === 'completedOn') {
          sortDirection =
            direction === SortType.DESC ? SortType.ASC : SortType.DESC;
        }

        const isTaskComplete = taskStatus === TASK_STATUS.COMPLETE;
        const isSortByCreatedOn = sort === 'createdOn';
        const shouldSortByCompletedOn = isTaskComplete && isSortByCreatedOn;

        sorts.push({
          property: shouldSortByCompletedOn ? 'completedOn' : sort,
          direction: sortDirection
        });
      } else {
        sorts.push(DEFAULT_SORTING);
      }

      sorts.push({
        property: 'carHandoverOn',
        direction: SortType.ASC
      });

      return createFilterPayload({
        filter,
        sorts,
        page,
        pageSize
      });
    }
  );
