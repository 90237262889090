/* eslint-disable prettier/prettier */
import { ClearOutlined, SearchOutlined } from '@ant-design/icons';
import {
  DatePickerRangeControlled,
  OptionModel,
  SelectControlled
} from '@retail/backoffice-ui';
import { useUserContext } from '@retail/backoffice-ui/src/UserContext';
import { CONTROLLER_DEFAULT_VALUE } from '@retail/hooks';
import { Button, Card, Col, Form, Row, Tooltip } from 'antd';
import { Gutter } from 'antd/lib/grid/row';
import { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  FLOW_VERSION_OPTIONS,
  NO_ASSIGNEE,
  NO_TASK_ACTION,
  TASK_STATUS_OPTIONS
} from '../constants';

import { FieldCol } from './FieldCol';
import cn from './styles.less';

import { InputControlled } from '~components/form/input';
import { IUserContext } from '~providers/UserProvider';
import { Assignee } from '~types/Assignee';

const GUTTER_16_16: [Gutter, Gutter] = [16, 16];

interface Props {
  refetch: VoidFunction;
  controller: FieldValues;
  assignees: Assignee[];
  taskNames: OptionModel[];
  orderStatuses: OptionModel[];
  isOptionsLoading: boolean;
  isLoading?: boolean;
  orderRetailCountries: OptionModel[];
  clearController: VoidFunction;
  updateController: (data: FieldValues) => void;
  paymentTypes: OptionModel[];
  paymentStatuses: OptionModel[];
  financingStatuses: OptionModel[];
  taskActions: OptionModel[];
}

export const SearchForm = memo<Props>(
  ({
    refetch,
    controller,
    isLoading,
    clearController,
    updateController,
    assignees,
    taskNames,
    isOptionsLoading,
    orderStatuses,
    orderRetailCountries,
    paymentTypes,
    paymentStatuses,
    taskActions,
    financingStatuses
  }) => {
    const { t } = useTranslation();
    const { id: currentUserId } = useUserContext<IUserContext>();
    const submitButtonRef = useRef(null);

    const {
      handleSubmit,
      control,
      reset,
      watch,
      formState: { isDirty },
      getValues
    } = useForm({
      defaultValues: CONTROLLER_DEFAULT_VALUE as FieldValues,
      mode: 'all'
    });

    const assigneeValue = watch('assignee');

    const assigneesOptions = useMemo(
      () => [
        {
          value: NO_ASSIGNEE,
          label: t('bo.taskManagement.processing.unassigned')
        },
        ...assignees
      ],
      [assignees, t]
    );
    const taskActionsOptions = useMemo(
      () => [
        {
          value: NO_TASK_ACTION,
          label: t('bo.taskManagement.search.taskActions.noTaskAction')
        },
        ...taskActions
      ],
      [taskActions, t]
    );

    useEffect(() => {
      reset(controller);
    }, [controller, getValues, reset]);

    const taskStatusOptions = useMemo(
      () =>
        TASK_STATUS_OPTIONS.map((item) => ({
          ...item,
          label: t(`bo.taskManagement.type.${item.label}`)
        })),
      [t]
    );

    const onSubmit = useCallback(
      (values) => {
        if (isDirty) {
          updateController({ ...values, page: 1 });
        } else {
          refetch();
        }
      },
      [refetch, isDirty, updateController]
    );

    const onAssignedToMeClick = useCallback(
      (values) => {
        updateController({ ...values, assignee: [currentUserId], page: 1 });
      },
      [currentUserId, updateController]
    );
    1;
    const onSelectChange = () => submitButtonRef.current.focus();

    return (
      <Card title={t('bo.taskManagement.searchTitle')}>
        <Form
          className={cn.form}
          disabled={isLoading}
          layout="vertical"
          onFinish={handleSubmit(onSubmit)}
        >
          <Row gutter={GUTTER_16_16}>
            <Col xs={24}>
              <Row gutter={GUTTER_16_16}>
                <Col xs={24}>
                  <SelectControlled
                    allowClear
                    mode="multiple"
                    options={taskNames}
                    disabled={isOptionsLoading}
                    data-qa-selector="taskType"
                    controllerProps={{ name: 'taskType', control }}
                    label={t('bo.taskManagement.search.label.taskType')}
                    placeholder={t(
                      'bo.taskManagement.search.placeholder.taskType'
                    )}
                  />
                </Col>
                <FieldCol>
                  <SelectControlled
                    allowClear
                    showSearch
                    mode="multiple"
                    options={assigneesOptions}
                    disabled={isOptionsLoading}
                    data-qa-selector="assignee"
                    dropdownStyle={{ minWidth: '250px' }}
                    controllerProps={{ name: 'assignee', control }}
                    label={t('bo.taskManagement.search.label.assignee')}
                    placeholder={t(
                      'bo.taskManagement.search.placeholder.assignee'
                    )}
                  />
                </FieldCol>
                <FieldCol>
                  <SelectControlled
                    allowClear
                    showSearch
                    options={taskStatusOptions}
                    onSelect={onSelectChange}
                    disabled={isOptionsLoading}
                    data-qa-selector="taskStatus"
                    controllerProps={{ name: 'taskStatus', control }}
                    label={t('bo.taskManagement.search.label.taskStatus')}
                    placeholder={t(
                      'bo.taskManagement.search.placeholder.taskStatus'
                    )}
                  />
                </FieldCol>
                <FieldCol>
                  <SelectControlled
                    allowClear
                    mode="multiple"
                    disabled={isOptionsLoading}
                    options={orderRetailCountries}
                    data-qa-selector="country"
                    controllerProps={{ name: 'country', control }}
                    label={t('bo.taskManagement.search.label.country')}
                    placeholder={t(
                      'bo.taskManagement.search.placeholder.country'
                    )}
                  />
                </FieldCol>
                <FieldCol>
                  <DatePickerRangeControlled
                    allowClear
                    dateFormat="DD/MM/YYYY"
                    disabled={isLoading}
                    data-qa-selector="createdOn"
                    controllerProps={{ name: 'createdOn', control }}
                    label={t('bo.taskManagement.search.label.createdOn')}
                    style={{ width: '100%' }}
                  />
                </FieldCol>
                <FieldCol>
                  <DatePickerRangeControlled
                    allowClear
                    dateFormat="DD/MM/YYYY"
                    disabled={isLoading}
                    data-qa-selector="completedOn"
                    controllerProps={{ name: 'completedOn', control }}
                    label={t('bo.taskManagement.search.label.completedOn')}
                    style={{ width: '100%' }}
                  />
                </FieldCol>
                <FieldCol>
                  <SelectControlled
                    allowClear
                    showSearch
                    mode="multiple"
                    options={orderStatuses}
                    disabled={isOptionsLoading}
                    onSelect={onSelectChange}
                    data-qa-selector="orderStatus"
                    controllerProps={{ name: 'orderStatus', control }}
                    label={t('bo.taskManagement.search.label.orderStatus')}
                    placeholder={t(
                      'bo.taskManagement.search.placeholder.orderStatus'
                    )}
                  />
                </FieldCol>
                <FieldCol>
                  <InputControlled
                    type="string"
                    disabled={isLoading}
                    data-qa-selector="orderNumber"
                    label={t('bo.taskManagement.search.orderNumber.title')}
                    placeholder={t(
                      'bo.taskManagement.search.orderNumber.placeholder'
                    )}
                    controllerProps={{
                      name: 'orderNumber',
                      control
                    }}
                  />
                </FieldCol>
                <FieldCol>
                  <InputControlled
                    type="string"
                    data-qa-selector="stockNumber"
                    disabled={isLoading}
                    label={t('bo.taskManagement.search.stockNumber.title')}
                    placeholder={t(
                      'bo.taskManagement.search.stockNumber.placeholder'
                    )}
                    controllerProps={{
                      name: 'stockNumber',
                      control
                    }}
                  />
                </FieldCol>
                <FieldCol>
                  <SelectControlled
                    allowClear
                    showSearch
                    mode="multiple"
                    options={paymentTypes}
                    disabled={isOptionsLoading}
                    data-qa-selector="paymentType"
                    dropdownStyle={{ minWidth: '250px' }}
                    controllerProps={{ name: 'paymentType', control }}
                    label={t('bo.taskManagement.search.label.paymentType')}
                    placeholder={t(
                      'bo.taskManagement.search.placeholder.paymentType'
                    )}
                  />
                </FieldCol>
                <FieldCol>
                  <SelectControlled
                    allowClear
                    showSearch
                    mode="multiple"
                    options={financingStatuses}
                    disabled={isOptionsLoading}
                    data-qa-selector="financingStatus"
                    dropdownStyle={{ minWidth: '250px' }}
                    controllerProps={{ name: 'financingStatus', control }}
                    label={t('bo.taskManagement.search.label.financingStatus')}
                    placeholder={t(
                      'bo.taskManagement.search.placeholder.financingStatus'
                    )}
                  />
                </FieldCol>
                <FieldCol>
                  <SelectControlled
                    allowClear
                    showSearch
                    mode="multiple"
                    options={paymentStatuses}
                    disabled={isOptionsLoading}
                    data-qa-selector="paymentStatus"
                    dropdownStyle={{ minWidth: '250px' }}
                    controllerProps={{ name: 'paymentStatus', control }}
                    label={t('bo.taskManagement.search.label.paymentStatus')}
                    placeholder={t(
                      'bo.taskManagement.search.placeholder.paymentStatus'
                    )}
                  />
                </FieldCol>
                <FieldCol>
                  <SelectControlled
                    allowClear
                    showSearch
                    mode="multiple"
                    options={FLOW_VERSION_OPTIONS}
                    disabled={isOptionsLoading}
                    data-qa-selector="flowVersion"
                    dropdownStyle={{ minWidth: '250px' }}
                    controllerProps={{ name: 'flowVersion', control }}
                    label={t('bo.taskManagement.search.label.flowVersion')}
                    placeholder={t(
                      'bo.taskManagement.search.placeholder.flowVersion'
                    )}
                  />
                </FieldCol>
              </Row>
            </Col>
            <Col xs={24}>
              <Row gutter={GUTTER_16_16} justify="end" align="bottom">
                <Col sm={24} lg={12}>
                  <SelectControlled
                    allowClear
                    showSearch
                    mode="multiple"
                    options={taskActionsOptions}
                    disabled={isOptionsLoading}
                    data-qa-selector="taskActions"
                    dropdownStyle={{ minWidth: '250px' }}
                    controllerProps={{ name: 'action', control }}
                    label={t('bo.taskManagement.search.label.taskActions')}
                    placeholder={t(
                      'bo.taskManagement.search.placeholder.taskActions'
                    )}
                  />
                </Col>
                <Col flex="auto" />
                <Col>
                  <Row gutter={GUTTER_16_16} justify="end" align="bottom">
                    <Col>
                      <Button
                        data-qa-selector="applyAssignedToMe"
                        onClick={handleSubmit(onAssignedToMeClick)}
                        disabled={isLoading || assigneeValue === currentUserId}
                        type="primary"
                      >
                        {t('bo.taskManagement.processing.assignedToMe')}
                      </Button>
                    </Col>
                    <Col>
                      <Row gutter={GUTTER_16_16}>
                        <Col>
                          <Tooltip
                            placement="top"
                            title={t('bo.taskManagement.clear-search.tooltip')}
                          >
                            <Button
                              type="default"
                              onClick={clearController}
                              data-qa-selector="clearSearch"
                              disabled={isLoading}
                              icon={<ClearOutlined />}
                            />
                          </Tooltip>
                        </Col>
                        <Col>
                          <Tooltip
                            placement="top"
                            title={t(
                              'bo.taskManagement.processing.apply-search.tooltip'
                            )}
                          >
                            <Button
                              ref={submitButtonRef}
                              data-qa-selector="applySearch"
                              onClick={handleSubmit(onSubmit)}
                              loading={isLoading}
                              htmlType="submit"
                              type="primary"
                              icon={<SearchOutlined />}
                            />
                          </Tooltip>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Card>
    );
  }
);

SearchForm.displayName = 'Search';
