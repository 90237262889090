import { MinusSquareOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { Col, Pagination, Row, Table } from 'antd';
import React, { Dispatch, memo, useCallback, useMemo, useState } from 'react';
import ReactHelmet from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { SearchForm } from './SearchForm';
import { TaskEntity } from './types';
import { useSearchTaskManagerTasksQueryAsync } from './hooks/useSearchTaskManagerTasksQueryAsync';
import { useTasksManagerSearchOptionsQueryAsync } from './hooks/useTasksManagerSearchOptionsQueryAsync';
import cn from './styles.less';
import { ExpandedRow } from './Table/ExpandedRow';
import { TaskModal } from './TaskModal';

import { DEFAULT_PAGE_OPTIONS } from '~constants/common';

export const TaskManagement = memo(() => {
  const [expandedRowKeys, setExpandedRowKeys] = useState<React.Key[]>([]);
  const { t } = useTranslation();

  const {
    columns,
    orderStatuses,
    orderRetailCountries,
    taskNames,
    assignees,
    optionsLoading,
    paymentTypes,
    paymentStatuses,
    financingStatuses,
    taskActions,
    isCommentAllowedByTaskTypeMap,
    taskNameToAvailableActions
  } = useTasksManagerSearchOptionsQueryAsync({
    setExpandedRowKeys,
    expandedRowKeys
  });
  const {
    controller,
    updateController,
    clearController,
    onChange,
    onPageChange,
    taskManagerTasks,
    taskManagerTasksLoading,
    taskManagerTasksRefetch
  } = useSearchTaskManagerTasksQueryAsync();

  const allRowsKeys = useMemo(
    () => taskManagerTasks.map((task) => task.id),
    [taskManagerTasks]
  );

  const onPageChangeCallback = (page: number, pageSize: number) => {
    setExpandedRowKeys([]);
    onPageChange(page, pageSize);
  };

  const isAllExpanded = expandedRowKeys.length === allRowsKeys.length;

  const onExpandAll = useCallback(() => {
    if (isAllExpanded) {
      setExpandedRowKeys([]);
    } else {
      setExpandedRowKeys(allRowsKeys);
    }
  }, [isAllExpanded, allRowsKeys]);

  return (
    <div className={cn.page}>
      <ReactHelmet title={t('bo.taskManagement.pageTitle')} />
      <TaskModal taskNameToAvailableActions={taskNameToAvailableActions} />
      <Row gutter={20}>
        <Col span={24}>
          <h2 className={cn.title}>{t('bo.taskManagement.pageTitle')}</h2>
        </Col>
      </Row>
      <Row gutter={20} className={cn.offsetBottom}>
        <Col span={24}>
          <SearchForm
            refetch={taskManagerTasksRefetch}
            isLoading={taskManagerTasksLoading}
            assignees={assignees}
            taskNames={taskNames}
            controller={controller}
            orderStatuses={orderStatuses}
            isOptionsLoading={optionsLoading}
            clearController={clearController}
            updateController={updateController}
            orderRetailCountries={orderRetailCountries}
            paymentTypes={paymentTypes}
            paymentStatuses={paymentStatuses}
            financingStatuses={financingStatuses}
            taskActions={taskActions}
          />
        </Col>
      </Row>
      <Row gutter={20} className={cn.offsetBottom}>
        <Col span={8} className={cn.centering}>
          <h3
            className={cn.subheading}
            data-qa-selector="task-management-total-tasks"
          >{`${t('bo.taskManagement.totalTasks')}: (${
            controller.totalPages ?? 0
          })`}</h3>
        </Col>
        <Col span={16} className={cn.pagination}>
          <Pagination
            onChange={onPageChangeCallback}
            current={controller.page}
            total={controller.totalPages}
            pageSize={controller.pageSize}
            disabled={taskManagerTasksLoading}
            pageSizeOptions={DEFAULT_PAGE_OPTIONS}
          />
        </Col>
      </Row>
      <Row className={cn.offsetBottom}>
        <Col span={24}>
          <div className={cn.expandAll}>
            {isAllExpanded ? (
              <MinusSquareOutlined onClick={onExpandAll} />
            ) : (
              <PlusSquareOutlined onClick={onExpandAll} />
            )}
          </div>
          <Table<TaskEntity>
            bordered
            rowKey="id"
            columns={columns}
            pagination={false}
            onChange={onChange}
            scroll={{ x: true }}
            loading={taskManagerTasksLoading}
            rowClassName="task-management-row"
            data-qa-selector="task-management-table"
            dataSource={taskManagerTasks}
            expandable={{
              expandedRowKeys,
              onExpandedRowsChange: setExpandedRowKeys as Dispatch<React.Key[]>,
              expandedRowRender: (data, index) => (
                <ExpandedRow
                  key={index}
                  data={data}
                  isCommentAllowedByTaskTypeMap={isCommentAllowedByTaskTypeMap}
                  taskManagerTasksRefetch={taskManagerTasksRefetch}
                  taskManagerTasksLoading={taskManagerTasksLoading}
                />
              )
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24} className={cn.pagination}>
          <Pagination
            onChange={onPageChangeCallback}
            current={controller.page}
            total={controller.totalPages}
            pageSize={controller.pageSize}
            disabled={taskManagerTasksLoading}
            pageSizeOptions={DEFAULT_PAGE_OPTIONS}
          />
        </Col>
      </Row>
    </div>
  );
});

export default TaskManagement;
