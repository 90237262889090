import { SortType } from '@retail/gql-utils';
import { ControllerModel, useSearchParamsController } from '@retail/hooks';
import { notification } from 'antd';
import { get } from 'lodash/fp';
import { useCallback, useMemo } from 'react';

import { TASK_STATUS } from '../constants';
import formToMeta from '../formToMeta';
import { TaskEntity } from '../types';

import { useSearchTaskManagerTasksQuery } from '~apollo/gql-types';
import { DEFAULT_SORTING } from '~constants/common';
import * as ROUTES from '~routes';
import { TaskManagerSearchModel } from '~types/TaskManagerSearchModel';

const DEFAULT_VALUE: TaskManagerSearchModel = {
  taskStatus: TASK_STATUS.INCOMPLETE,
  sort: DEFAULT_SORTING.property,
  direction: DEFAULT_SORTING.direction,
  orderNumber: null,
  stockNumber: null,
  taskType: [],
  assignee: [],
  country: [],
  createdOn: [],
  completedOn: [],
  orderStatus: [],
  action: [],
  financingStatus: [],
  flowVersion: [],
  paymentStatus: [],
  paymentType: []
};

export function useSearchTaskManagerTasksQueryAsync() {
  const { controller, onPageChange, updateController, clearController } =
    useSearchParamsController<ControllerModel & Partial<typeof DEFAULT_VALUE>>(
      ({ queryString, language, navigate }) =>
        navigate(`${ROUTES.TASK_MANAGEMENT.LINK({ language })}${queryString}`),
      DEFAULT_VALUE
    );

  const {
    data: taskManagerTasksData,
    loading: taskManagerTasksLoading,
    refetch: taskManagerTasksRefetch
  } = useSearchTaskManagerTasksQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      search: formToMeta()(controller)
    },
    onCompleted: (data) => {
      updateController({
        totalPages: data?.searchTaskManagerTasks?.totalEntityCount
      });
    },
    onError: (error) => {
      notification.error({
        message: 'Error',
        description: get('0.message')(error?.graphQLErrors)
      });
    },
    errorPolicy: 'all'
  });

  const onChange = useCallback(
    (pagination, filters, sorter, extra) => {
      const { action } = extra;
      const { columnKey: field, order } = sorter;
      if (action === 'sort' && order) {
        updateController({
          page: 1,
          sort: field,
          direction: order === 'ascend' ? SortType.ASC : SortType.DESC
        });
      } else {
        updateController({
          page: 1,
          sort: 'createdOn',
          direction: SortType.ASC
        });
      }
    },
    [updateController]
  );

  return useMemo(() => {
    const taskManagerTasks =
      (taskManagerTasksData?.searchTaskManagerTasks?.entities?.filter(
        (it) => 'id' in it
      ) as TaskEntity[]) ?? [];

    return {
      controller,
      updateController,
      clearController,
      onChange,
      onPageChange,
      taskManagerTasks,
      taskManagerTasksLoading,
      taskManagerTasksRefetch
    };
  }, [
    taskManagerTasksData?.searchTaskManagerTasks?.entities,
    controller,
    updateController,
    clearController,
    onChange,
    onPageChange,
    taskManagerTasksLoading,
    taskManagerTasksRefetch
  ]);
}
